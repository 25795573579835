import React, { useState, useEffect, useRef } from 'react'
import Header from './components/Header'
import { Contactform } from './components/Contactform'
import { Footer } from './components/Footer'
import Slider from 'react-slick';
import img02 from './images/wifi-extender.webp';
import axios from 'axios';
import { Link } from "react-scroll";

export const Wifi = () => {

    var decodeHTML = function (html) {
        var txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    };

    function createMarkup(content) {
        return { __html: decodeHTML(content) };
    }

    const [pagedata, setPagedata] = useState([]);
    useEffect(() => {
        let mounted = true;
        const loadData = async () => {
            const response = await axios.get(window.weburl + `api/plans.php?catID=7`)
            if (mounted) {
                setPagedata(response.data);
            }
        }

        loadData();

        return () => {
            mounted = false;
        };
    }, []);

    console.log(pagedata);
    if (pagedata.length === 0) {
        console.log('no data');
        // return null;
    }

    const [user_mobile, setusermobile] = useState();
    const [plans, setPlans] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const ref_mobile = useRef(null);

    const [selectedPlan, setSelectedPlan] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        axios.get(window.weburl + 'api/get_plans.php?cid=13')
            .then(response => setPlans(response.data))
            .catch(error => console.log(error));
    }, []);

    useEffect(() => {
        axios.get(window.weburl + 'api/get_states.php')
            .then(response => setStates(response.data))
            .catch(error => console.log(error));
    }, []);

    function getCities(state) {
        axios.get(window.weburl + `api/get_cities.php?sid=${state}`)
            .then(response => setCities(response.data))
            .catch(error => console.log(error));
    }

    const handledisable = () => {
        if (isValidPhone(user_mobile) && (user_mobile)) {
            const element = document.getElementById('submit_button_text');
            element.classList.remove('disabled');
            setIsDisabled(false);
        } else {
            const element = document.getElementById('submit_button_text');
            element.classList.add('disabled');
            setIsDisabled(true);
        }
    };

    function isValidPhone(phone) {
        const regex = /^[6-9]\d{9}$/gi;
        return regex.test(phone);
    }

    const handleSubmit = () => {
        if (!isValidPhone(user_mobile)) {
            alert("Please enter Mobile number");
            ref_mobile.current.focus();
            return false;
        }
        else {
            const url = window.weburl + "api/insert_hook.php";
            let fData = new FormData();
            fData.append('user_mobile', user_mobile);
            fData.append('catid', '7');
            fData.append('pid', selectedPlan);
            fData.append('user_state', selectedState);
            fData.append('user_city', selectedCity);
            fData.append('td_name', 'androidtv');
            fData.append('utm_url', window.location.href);
            axios.post(url, fData)
                .then(response => alert(response.data))
                .catch(error => alert(error));
            window.location.reload();
        }
    }

    var settings = {
        dots: true,
        navigation: true,
        loop: true,
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        speed: 300,
        autoplaySpeed: 5000,
        arrows: false,
        cssEase: "linear"
    };
    return (
        <>
            <Header />
            <section id='banner' className='p-0' data-aos="fade-left" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                <div className='banner mngslide'>
                    <div className='container'>
                        <div className='row'>
                            <Slider {...settings}>
                                <div>
                                    <div className='sliderrow'>
                                        <div className='slideimg'>
                                            <img src={img02} alt='Banner Netplus' />
                                        </div>
                                        <div className='slidecont'>
                                            <div className='circle01'>
                                                <div className='mngcircle'>
                                                    <div className='circlimg'>
                                                        <h2>Netplus Wireless Wi-Fi Extender</h2>
                                                    </div>
                                                    <div className='pack'>
                                                        <h5>now at <strong><i className='finr'></i> 3500/-</strong></h5>
                                                    </div>
                                                    {/* <div className='booknow'>
                                                        <Link
                                                            to="contactus"
                                                            smooth={true}
                                                            duration={50}
                                                            offset={-40}
                                                        >
                                                            BOOK NOW
                                                        </Link>

                                                    </div> */}




                                                </div>
                                            </div>
                                            <div className='circle02'>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
            <section id='filter'>
                <div className='filter'>
                    <div className="container">
                        <div className="row">
                            <div className="web-container">
                                <div className="footerform" data-aos="fade-up" data-aos-offset="10" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <div className='heading'>
                                        <h4>Get it today</h4>
                                    </div>
                                    <div className="form">
                                        <form>
                                            <ul>
                                                <li>
                                                    <input type="text" name="user_mobile" id="user_mobile" placeholder='Mobile number*' pattern="[a-zA-Z\s]+" value={user_mobile} onChange={(e) => { setusermobile(e.target.value); }} onKeyUp={handledisable} ref={ref_mobile} />
                                                </li>
                                                <li><select name="selectedPlan" id="selectedPlan" value={selectedPlan} onChange={event => { setSelectedPlan(event.target.value); }} >
                                                    <option value="">Select Product</option>
                                                    {plans.map(plan => (
                                                        <option key={plan.sno} value={plan.sno} dangerouslySetInnerHTML={createMarkup(plan.pname + ' ' + plan.ptitle)}></option>
                                                    ))}
                                                </select></li>
                                                <li><select name="selectedState" id="selectedState" value={selectedState} onChange={event => { setSelectedState(event.target.value); getCities(event.target.value); }}>
                                                    <option value="">Select Region</option>
                                                    {states.map(state => (
                                                        <option key={state.stateid} value={state.stateid}>{state.sname}</option>
                                                    ))}
                                                </select></li>
                                                <li><select name="selectedCity" id="selectedCity" value={selectedCity} onChange={event => setSelectedCity(event.target.value)}>
                                                    <option value="">Select City</option>
                                                    {cities.map(city => (
                                                        <option key={city.cityid} value={city.cityid}>{city.cityname}</option>
                                                    ))}
                                                </select></li>
                                                <li className='buttnprop'><input type="submit" value="" id="submit_button" disabled={isDisabled} onClick={handleSubmit} /><span id="submit_button_text" className='disabled'>SUBMIT</span></li>
                                            </ul>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='enterprise'>
                <div className='enterprise'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='title' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <span>Wireless Wi-Fi Extender</span>
                                </div>
                                <div className='smartcont' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <p>Netplus wireless Wi-Fi extender is an 802.11ac dual-band 1200 Mbps Wireless router for mesh AP or Extender. The H196A provides users with a downstream dual-band Wi-Fi connection, and supports intelligent mesh networking and flexible application. It also supports plug-and-play, band steering, smart roaming, remote O&M and diagnosis through TR-069. The H196A is a green and energy-saving product and being the optimal choice for deploying home network solutions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='specification'>
                <div className='specification'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='heading' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <h4>The Netplus wireless Wi-Fi extender<br /> meets the following EIRP standard:</h4>
                                </div>
                                <div className='faqcont' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <table class="table table-striped">
                                        <tbody>
                                            <tr className='gradient'>
                                                <th>Wi-Fi Band</th>
                                                <th>Wi-Fi Channel</th>
                                                <th>Frequency</th>
                                                <th>EIRP</th>
                                            </tr>
                                            <tr>
                                                <td rowspan="2" align='center'>2.4 GHz (IEEE 802.11b/g/n)</td>
                                                <td>1 to 13</td>
                                                <td>2.400-2.4835 GHz</td>
                                                <td>Max 20 dBM. Comply with ETSI Standard EN 300 328</td>

                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>2.484 GHz</td>
                                                <td>Disabled</td>
                                            </tr>

                                            <tr>
                                                <td rowspan="2" align='center'>5 GHz (IEEE 802.11a/n/ac)</td>
                                                <td>36,40,44,48,52,56,60,64</td>
                                                <td>5.170-5.330 GHz</td>
                                                <td>Max 23 dBM. Comply with ETSI Standard EN 301 893</td>

                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>5.490-5.850 GHz</td>
                                                <td>Disabled</td>
                                            </tr>


                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Contactform /> */}
            <Footer />
        </>
    )
}
