import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { Svg } from "./Svg";
import { Link } from "react-scroll";
window.weburl = "https://www.netplus.co.in/";

export const Header = (props) => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
    window.addEventListener("scroll", changeBackground);
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);
  const [header, setNavbar] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  const [menu, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!menu);
  };
  const [mobilemenu, setActives] = useState("false");
  const handleToggle1 = () => {
    setActives(!mobilemenu);
  };
  const [mobilemenu2, setActives2] = useState("false");
  const handleToggle2 = () => {
    setActives2(!mobilemenu2);
  };
  const [mobilemenu3, setActives3] = useState("false");
  const handleToggle3 = () => {
    setActives3(!mobilemenu3);
  };
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const hoverClass = isHovered ? "hover" : "";
  return (
    <header id="header" className={`${hoverClass}`}>
      <div className={header ? "header fixed" : "header"}>
        <div className="container">
          <div className="row align-items-center">
            <div className="headerstn">
              <div className={menu ? "logo" : "logo active"}>
                <NavLink to="/">
                  <Svg />
                </NavLink>
              </div>
              <div className={!menu ? "menu active" : "menu"}>
                <div className="navbar">
                  <ul>

                  <li>
                      <NavLink to="/book-now/bVhQYTVDZ1hCN3JkWnppc0g2N2lidz09"><strong>Get New Connection</strong></NavLink>
                    </li>

                    <li
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <NavLink to="/retail" className="desktop-show">
                      <strong>Broadband</strong>
                      </NavLink>
                      <a className="mobile-show"><strong>Retail</strong></a>
                      <div className="dropdown">
                        <div className="submenu">
                          <div className="container">
                            <div className="menubox">
                              <div className="sitemapcol">
                                <NavLink to="/retail" className="mobile-show">
                                  {" "}
                                  <h4>Retail</h4>
                                </NavLink>
                              </div>
                              <div className="sitemapcol">
                                <h4>
                                  <a href="/retail#app">Custom Plans</a>
                                </h4>
                              </div>
                              <div className="sitemapcol">
                                <h4>
                                  <NavLink to="/broadband-plans">
                                    Broadband plans
                                  </NavLink>
                                </h4>
                              </div>
                             
                              <div className="sitemapcol">
                                <h4>
                                  <NavLink to="/entertainment-plans">
                                    Entertainment plans
                                  </NavLink>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    
                    <li>
                      <NavLink to="/ip-tv"> <strong>IPTV</strong></NavLink>
                    </li>

                    {/* <li>
                      <NavLink to="/android-tv"> <strong>ANDROID TV BOX</strong></NavLink>
                    </li> */}

                    <li>
                      <NavLink to="/netplus-hd-bundle">
                       <strong> NetPlus HD Bundle</strong>
                      </NavLink>
                    </li>

                   

                   

                    <li
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <NavLink to="/retail" className="desktop-show">
                      <strong>Enterprise</strong>
                      </NavLink>
                      <a className="mobile-show"><strong>Enterprise</strong></a>
                      <div className="dropdown">
                        <div className="submenu">
                          <div className="container">
                            <div className="menubox">
                              <div className="sitemapcol">
                              <h4>
                                  <NavLink to="/enterprise">Internet Leased Line</NavLink>
                                </h4>
                              </div>
                              
                              <div className="sitemapcol">
                              <h4>
                                  <NavLink to="/smart-city-projects">Smart City Project</NavLink>
                                </h4>
                              </div>
                              
                              <div className="sitemapcol">
                                <h4>
                                  <NavLink to="/system-intigration-projects">System Integration Projects</NavLink>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                 

                  

                    <li
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <NavLink to="/retail" className="desktop-show">
                      <strong>Value added Services</strong>
                      </NavLink>
                      <a className="mobile-show"><strong>Retail</strong></a>
                      <div className="dropdown">
                        <div className="submenu">
                          <div className="container">
                            <div className="menubox">
                              <div className="sitemapcol">
                              <h4>
                                  <NavLink to="/leadline-phone">
                                    Landline Phone
                                  </NavLink>
                                </h4>
                              </div>
                              
                              <div className="sitemapcol">
                                <h4>
                                  <NavLink to="/wifi-extender">
                                    Range Extender
                                  </NavLink>
                                </h4>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>  

                    

                    <li>
                      <NavLink to="/pay-bill"><strong>Pay Bill / Recharge</strong></NavLink>
                    </li>
                    <li>
                      <a
                        href="https://selfcare.netplus.co.in/#/login"
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlSpace="preserve"
                          width={384}
                          height={447.999}
                          style={{
                            shapeRendering: "geometricPrecision",
                            textRendering: "geometricPreci    sion",
                            imageRendering: "optimizeQuality",
                            fillRule: "evenodd",
                            clipRule: "evenodd",
                          }}
                          viewBox="0 0 10160 11853.34"
                          {...props}
                        >
                          <path
                            d="M7542.53 5704.42c571.08-606.64 924.14-1420.71 924.14-2317.75C8466.67 1519.35 6947.33 0 5080 0 3212.67 0 1693.33 1519.34 1693.33 3386.67c0 897.04 353.06 1711.11 924.14 2317.75C924.98 6648.88 0 8649.55 0 11430c0 234.1 189.65 423.33 423.33 423.33l9313.34.01c234.1 0 423.33-189.23 423.33-423.33 0-2780.88-924.98-4781.13-2617.47-5725.58v-.01zM5080 846.67c1400.81 0 2540 1139.19 2540 2540s-1139.19 2540-2540 2540-2540-1139.19-2540-2540 1139.19-2540 2540-2540zm-4225.71 10160c66.46-1792.39 587.59-3891.7 2490.47-4717.2 508 305.22 1100.67 483.87 1735.24 483.87 634.57 0 1227.24-178.65 1735.24-483.87 1902.46 825.08 2424.01 2924.81 2490.05 4717.2h-8451z"
                            style={{
                              fill: "#d51e3f",
                            }}
                          />
                        </svg>
                        Account{" "}
                      </a>
                    </li>
                    <li className="mobile-show">
                      <NavLink to="/about-netplus">About Netplus</NavLink>
                    </li>
                    <li className="mobile-show">
                      <NavLink to="/jujhar-group">Jujhar Group</NavLink>
                    </li>
                    <li className="mobile-show">
                      <a
                        href="https://www.jujhargroup.com/career?c=Netplus Broadband Services Pvt. Ltd."
                        target="_blank"
                      >
                        Career
                      </a>
                    </li>
                    <li className="mobile-show">
                      <NavLink to="events">Downloads</NavLink>
                    </li>
                    <li className="mobile-show">
                      <NavLink to="/contact-us">Contact Us</NavLink>
                    </li>
                  </ul>
                </div>
                <div className="hemburgermenus">
                  <div className="dropdown">
                    <div className="submenu">
                      <div className="sitemapcol">
                        <ul>
                          <li>
                            <NavLink to="/about-netplus">About Netplus</NavLink>
                          </li>
                          <li>
                            <NavLink to="/jujhar-group">Jujhar Group</NavLink>
                          </li>
                          <li>
                            <a
                              href="https://www.jujhargroup.com/career?c=Netplus Broadband Services Pvt. Ltd."
                              target="_blank"
                            >
                              Career
                            </a>
                          </li>
                          <li>
                            <NavLink to="download">Downloads</NavLink>
                          </li>
                          <li>
                            <NavLink to="/contact-us">Contact Us</NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  onClick={handleToggle}
                  className={menu ? "hemburgur" : "hemburgur active"}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
